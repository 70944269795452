.inv-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.span-100 {
    width: 100%;
}

.span-100-nopad {
    width: 100%;
}

.span-100 * {
    margin-left: 2%;
}

.button-algn {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn-reset {
    margin-left: 5%;
}

.ch-w {
    min-width: 55%;
}

.inv-acc-card {
    padding: 2%;
}

.inv-acc-card h2{
    padding-bottom: 1%;
    border-bottom: rgb(131, 131, 131) 1px solid;
}

.inv-row-bet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inv-grouping {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}

.no-pad {
    padding: 0;
}

.inv-grouping-border {
    margin: 2%;
    padding-bottom: 1%;
    border-bottom: rgb(131, 131, 131) 1px solid;
}

.inv-grouping h3 {
    margin-bottom: 1%;
    padding-bottom: 1%;
    border-bottom: rgb(131, 131, 131) 1px solid;
}

.flex-no-just {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.last-bus-day {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}