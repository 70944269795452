.rater-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 1%;
}

.pad-0 {
    padding: 0
}

.mc-2 {
    margin-left: 1.5%;
    margin-right: 1.5%;
}