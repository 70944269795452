// @import '../../../index.scss';

.alert-container {
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 80px;
  z-index: 10000;
  .alert {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    text-align: center;
  }
  .btn-close {
    font-size: 12px;
    color: white !important;
  }
}

.alert-container::before {
  width: 100vw;
  height: 100vh;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  // background-color: black;
  // opacity: 0.5;
}

.alert-success {
  background-color: #0099f2be;
  color: white;
}

.alert-danger {
  background-color: rgba(170, 0, 0, 0.76);
  color: white;
}

.alert-warning {
  background-color: rgba(255, 102, 0, 0.76);

  color: white;
}
