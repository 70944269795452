@import "../index.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Noto+Sans+Kannada&display=swap");

// See index.scss for variable overrides from Bootstrap and custom variables

.AppBodyContainer {
  max-width: 1404px;
}

// Typography

h1 {
  color: $dark;
  font-family: "Noto Sans Kannada";
  font-size: $xLargeFont;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
}

nav li {
  color: $dark;
  font-family: "Noto Sans Kannada";
  font-size: $xLargeFont;
  letter-spacing: 0;
  line-height: 28px;
}

nav li a {
  color: $secondary;
  font-family: "Noto Sans Kannada";
  font-size: $xLargeFont;
  letter-spacing: 0;
}

a {
  color: $secondary;
  font-family: Helvetica;
  font-size: $mediumFont;
  font-weight: bold;
  letter-spacing: 0;
  // line-height: 17px;
  text-decoration: none;
}

p {
  font-family: Helvetica;
  font-size: $mediumFont;
  letter-spacing: 0;
  // line-height: 17px;
}

.read-only-info {
  padding-bottom: 14px;
  border-bottom: 1px solid #b3b5be;
  min-height: $mediumFont;
}

.input-read-only {
  background-color: white;
}

.read-only-label {
  font-weight: 500;
}

.ar-good-standing {
  color: $ar-good-standing;
}

.ar-bad-standing {
  color: $ar-bad-standing;
}

.ar-medium-standing {
  color: $ar-medium-standing;
}

.error-message {
  color: $errorMessage;
}

th {
  color: #101224;
  font-family: "Noto Sans Kannada";
  font-size: $mediumFont;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
}

// tr {
//     display: flex;
// }
td {
  color: #101224;
  font-family: "Noto Sans Kannada";
  font-size: $mediumFont;
  letter-spacing: 0;
  line-height: 22px;
  // display: inline-block;
  // align-self: flex-end;
  // min-height: 40px;
  vertical-align: center !important;
}

.borderlessButton {
  padding: 10px 8px 9px 8px;
  margin-bottom: $spacer * 0.5;
  display: inline-block;
  align-self: flex-end;
}

.error-message {
}

// ************************* Overrides Bootstrap classes

.row-form-group {
  align-items: center;
}

.card {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 12px 0 rgba(210, 212, 219, 0.5);
  padding: $spacer * 2;
}

.card-title {
  color: $dark;
  text-transform: uppercase;
  font-family: "Noto Sans Kannada";
  font-size: $mediumFont;
  font-weight: 700;
  letter-spacing: 2px;
  // line-height: 16px;
  margin-bottom: 33px;
  padding-top: $spacer;
}

.form-label {
  color: $darkblue;
  font-family: Helvetica;
  font-size: $smallFont;
  letter-spacing: 0;
  // line-height: 21px;
  padding-left: 0px;
  padding-right: 0px;
}

.btn {
  color: $light;
  font-family: "Noto Sans Kannada";
  font-size: $mediumFont;
  font-weight: 500;
  letter-spacing: 0;
  // line-height: 21px;
  padding: 15px 16px 10px 16px;
  border-radius: 4px;
}

.btn:hover {
  color: $light;
}

.btn-link {
  color: $secondary;
  font-family: Helvetica;
  font-size: $mediumFont;
  font-weight: bold;
  letter-spacing: 0;
  // line-height: 17px;
  padding: 11px 16px 8px 16px;
  text-decoration: none;
}

// ************************* Overrides Material UI elements
// input root
.MuiInputBase-root {
  // height: 60px;
  font-family: "Noto Sans Kannada";
  font-size: $mediumFont;
  letter-spacing: 0;
  // line-height: 21px;
  border-radius: none !important;
  padding: 0px 8px;
  background-color: $lightgray;

  input {
    // padding-top: 25px;
    padding-right: 0px;
    // padding-bottom: 8px;
    padding-left: 0px;
  }

  fieldset {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: none !important;
  }
}

.input-read-only .MuiInputBase-root {
  background-color: white !important;
}

.MuiInputBase-root.MuiFocused fieldset {
  // border-left: 1px solid $primary;
  // border-right: 1px solid $primary;
  // border-top: 1px solid $primary;

  border-radius: none !important;
}

// textarea
.MuiInputBase-multiline {
  font-family: "Noto Sans Kannada" !important;
  font-size: $mediumFont !important;
  letter-spacing: 0 !important;
  padding: $spacer * 2 !important;
  // line-height: 21px;
}

// basic input
.MuiInputBase-input {
  color: $darkblue;
  font-family: "Noto Sans Kannada" !important;
  font-size: $mediumFont !important;
  letter-spacing: 0 !important;
  // padding: 4px !important;
  // line-height: 21px;
  // padding-bottom: 5px;
}

.MuiInputBase-root {
  border-radius: 0px !important;
}

// autocomplete  need to add the class to the element each time unfortunately.
.autocomplete-input > .MuiInputBase-root {
  border-radius: none;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid $dark !important;
  background-color: $lightgray !important;
}

// this removes the up and down arrows in number inputs
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Overrided Label
.MuiInputLabel-root {
  background-color: white;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.MuiInputBase-root input {
  padding-bottom: 11px;
  border-radius: 0px;
}

.MuiAutocomplete-input {
  padding-bottom: 2px !important;
  border-radius: 0px;
}

.muiDatePicker .MuiInputBase-input {
  padding-bottom: 11px !important;
  border-radius: 0px;
}

.select-input > div {
  padding: 15px 11px 10px 10px;
}

// input {
// padding-bottom: 4px !important;
// }
