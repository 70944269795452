.Heading {
  .title {
    color: #101224;
    font-family: Helvetica;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 34px;
  }
  .subtitle {
    color: #101224;
    font-family: "Noto Sans Kannada";
    font-size: 21px;
    letter-spacing: 0;
    line-height: 28px;
  }
}
