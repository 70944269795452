.sugg-forms {
    display: flex;
    flex-direction: row;
    vertical-align: center;
    justify-content: space-around;
    flex-wrap: wrap
}

.sugg-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap
}

.sugg-alerts {
    margin-bottom: 1%;
}

.sugg-alerts .sugg-alert-body {
    margin-top: 1%;
}

.sugg-card {
    padding: 2%;
}

.sugg-card h2{
    padding-bottom: 1%;
    border-bottom: rgb(131, 131, 131) 1px solid;
}

@media screen and (max-width: 720px) {
    .sugg-card {
        padding: 5%;
    }
    
}