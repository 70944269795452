.OrderItems {
  .order-item-icons {
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 10px;
    margin-right: 10px;

    button {
      border: none;
      background-color: transparent;
      color: #f9af0f;
    }
  }
}

.xref-popup {
  background-color: rebeccapurple;
  &__table {
    & > div {
      height: calc(100vh - 452px);
      overflow-y: auto;
    }
  }

  &__guide {
    font-size: 13px;
    color: #686868;
    font-style: italic;
  }
}