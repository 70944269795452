
.Navigation {

  .nav {
    float: right !important;
  }

  .icon-button {
    margin-left: 0px;
    padding-left: 0px;
    padding-top: 0px;
  }

  .logo-title {
    color: #282a3b;
    font-family: Neutro;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
  }

  .nav-link a,
  .nav .MuiButton-root a,
  .nav .MuiButton-root span {
    color: #009bf2;
    font-family: "Noto Sans Kannada";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: capitalize;
    font-weight: bold;

    &.active {
      color: #101224;
    }
  }

  .nav-divider,
  .nav-divider-empty {
    font-size: 19px;
  }

  display: flex;
  align-items: center;
}

@media screen and (max-width: 720px) {
  .no-pad {
    padding: 0;
    margin:0
  }
  .test {
    width: 20%;
    margin: 0;
    font-size: 12px;
  }

  .nav Button{
    width: 15%;
    margin: 4px;
  }

  .test Button {
    padding: 0
  }

  .navigationContainer {
    padding: 0
  }
}