.drop-ship-popup {
  background-color: rebeccapurple;
  &__table {
    & > div {
      max-height: calc(100vh - 462px);
      overflow-y: auto;
    }
  }

  &__guide {
    font-size: 13px;
    color: #686868;
    font-style: italic;
  }
}



.drop-ship-edit-icon {
  border: none;
  background-color: transparent;
  padding: 0;
}

.package-rater-options-popup {
  &__table {
    & > div {
      height: calc(100vh - 220px);
      overflow: auto;
    }
  }
}

.package-rater-options-popup {
  &__packages-div {
    height: 420px;
    overflow-y: auto;
  }
}

.add-new-package-button {
  color: #007dc0;
}

.add-new-package-button:hover {
  color: white;
}

.package-delete-icon {
  border: none;
  background-color: transparent;
  color: #009bf2;
}

.little-warning {
  font-size: 13px;
  font-style: italic;
}

.package-results {
  margin-top: 25px;
}

.mui-custom-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bol-append-popup {
  background-color: rebeccapurple;
  &__table {
    & > div {
      height: calc(100vh - 372px);
      overflow-y: auto;
    }
  }
}

.bol-detail-popup {
  background-color: rebeccapurple;
  &__table {
    & > div {
      max-height: calc(100vh - 280px);
      overflow-y: auto;
    }
  }
}

.side-rater {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  .freightRaterPop {
    display: flex;
    flex-direction: column;
  }

  .hand-rate {
    display: flex;
    flex-direction: row;
    }


}
