.status-boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 1%;
    margin-bottom: 1%;
}

.warning-box {
    margin-top: 1%;
}