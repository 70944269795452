.advanced-customer-search-popup {
  background-color: rebeccapurple;
  &__table {
    & > div {
      height: calc(100vh - 452px);
      overflow-y: auto;
    }
  }

  &__guide {
    font-size: 13px;
    color: #686868;
    font-style: italic;
  }
}
