
.productCode {
  input {
    text-transform: uppercase;
  }
}

td {
  padding-left: 1px !important;
  padding-right: 1px !important;
}


.ts-1-1 {
  display: flex;
  flex-direction: row;
}


.item-sub-alert{ 
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.item-subs-text {
  padding: 0;
  margin-left: 1%;

}

.item-subs-btn {
  margin-left: 5%;
  width: 20%;

}

.tbCircleCheck {
  color: green;
  font-size: 130%;
  margin-top: 3px;
}
.whole {
  padding-bottom: 1%;
  border-bottom-style: dotted;
  border-bottom-color: rgb(156, 154, 154);
  border-bottom-width: 1px;
}

.span-btn {
  margin-top: 0;
}

.ts-1 {
  width: 35%
}

.w-80 {
  width: 90%
}

.description-line div {
  padding: 0 !important;
}


@media screen and (min-width: 1000px) {
  .wrapper {
      display: flex;
      flex-direction: row;   
  }
  
}

@media screen and (max-width: 1090px) {
  table {
    display: inline-table
  }
}

@media screen and (max-width: 720px) {
  .ts-1, .ts-2, .ts-3, .ts-4, .ts-5 {
    width: 100%;
    
  }

  .w-80 {
    width: 100%
  }
  

  .item-sub-alert{ 
    display: flex;
    flex-direction: column;
  }
  .item-subs-btn {
    margin-left: 0;
    width: 100%;
  }

  .cus-box {
    margin-top: 10%
  }

  .tb-1, .tb-2, .tb-3 {
    background: rgba(238, 237, 237, 0.699);
    z-index: -5
  }

  .tb-2 td{
    width: fit-content;
  }

  .wrapper {
    border-bottom-style: dotted;
    border-bottom-color: rgb(0, 0, 0);
    border-bottom-width: 1px;
  }
}