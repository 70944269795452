.entry-row-credit {
    display: flex;
    justify-items: space-between;
}


.credit-row {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.start-credit-btn {
    margin-top: 2%;
    float: right;
}

.b-left {
    border-left: black solid 1px;
    width: 10px;
}

.delete-image {
    text-align: center;
    font-weight: bold;

}

.delete-image:hover {
    cursor: pointer;
    background-color: rgb(206, 218, 241);
    border-radius: 5%;
}
.w-200 {
    width: 10vw;
}

.pt-0 {
    padding-top: 0
}

.pb-0 {
    padding-bottom: 0
}

.bottom-border-seperator {
    border-bottom: solid rgb(184, 184, 184) 1px;
    padding-bottom: 1%;
}

.credit-image {
    max-height: 100%;
    max-width: 100%;
}

.space-around {
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap:25px;
}

.del-tool {
    color: red;
}



@media screen and (max-width: 1000px) {
    .credit-row * {
        width: 100%
    }
}

@media screen and (max-width: 720px) {
    .credit-row * {
        width: 100%
    }
    .delete-image {
        max-width: 100%;
        text-align: center;
        font-weight: bold;
        margin-top: 2%;
    }
}