// @import '../../../index.scss';
// @import '../../../App.scss';

.ARBalance {
  .ar-standing {
    font-family: "Noto Sans Kannada";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: auto;
    margin-top: 20px;
  }
}

.info {
  margin-left: 10%;
}



@media screen and (max-width: 720px) {
  .c1 {
    background-color:rgb(221, 221, 221)
  }
  
  .c2 {
    background-color:rgb(192, 190, 190)
  }
}