.embed {
    margin: 2%;
    padding-bottom:1px;
    padding-right: 1px;
    box-shadow: inset 2px 2px 2px 2px#555;
    border-radius: 5px;
}

.embed * {
    padding-left: 2%;
    margin-lefT: 1%;
}

.btn-subs-left {
    margin-left: 20%;
}

.btn-subs-left,.btn-subs-right {
    width: 20%;
    font-size: 120%;
}

.subs-table tr * {
    font-size: 70%;
    padding-left: 1%;
}

.subs-table {
    margin: 2%;
}

.subs-table-head {
    border-bottom: solid 2px black;

}

.subs-table-head th {
    font-weight: bold;
}

.subs-table tr td {
    border-right: dashed 1px rgb(185, 185, 185);

    &:last-child {
        border-right: none;
        font-size: 50%;
    }
}


.subs-table tr {
    
    &:nth-child(2n) {
        background-color: rgb(126, 217, 253)
    }
}

.second-subs {
    width: 100;
    background-color:rgb(228, 227, 227);
}

@media screen and (max-width: 720px) {
    .embed * {
        margin-left: 1.5%;
    }
    .btn-subs-left,.btn-subs-right {
        width: 30%;
        font-size: 120%;
        margin-left: 12.5% !important;
    }
}