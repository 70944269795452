.SubOrder {
  .ar-standing-message {
    font-family: "Noto Sans Kannada";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    margin-right: auto;
    margin-top: 20px;
  }

  #drop-button-Draft {
    background-color: #ad66fc !important;
    border: #ad66fc !important;

    // background-color: $draft !important;
    &:hover {
      background-color: #9b60df !important;
      border: #ad66fc !important;
      color: white;
    }
  }
  .Heading .title {
    border-left: 6px solid #d8edfa;
    padding-left: 9px;
  }
  .card {
    border-left: 4px solid #d8edfa;
  }
}
