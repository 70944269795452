// Override default variables before the import from Bootstrap 5

$primary: #007dc0;
$secondary: #009bf2;
$success: #f7f7f7;
$info: #f6b628;
$warning: #f7f7f7;
$danger: #a00;
$light: #f0f0f0;
$dark: #101224;
$draft: #ad66fc;
$light-danger: #f8d7da;
$light-white: #ffffff;
$guide-color: #6c757d;
$form-error: #d32f2f;
$lightest: #f7f7f7;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "draft": $draft,
  "light-danger": $light-danger,
  "light-white": $light-white,
  "guide-color": $guide-color,
  "form-error": $form-error,
  "lightest": $lightest,
);

$spacer: 8px;

$body-bg: white;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1270px,
  xxl: 1320px,
);

@import "./assets/sass/custom-bootstrap.scss";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Additional Variables for typography and forms
$darkblue: #282a3b;
$lightgray: rgba(0, 0, 0, 0.06);

// colors for accounts receivable section
$ar-good-standing: #1ca935;
$ar-bad-standing: $danger;
$ar-medium-standing: $info;

// error message color
$errorMessage: $danger;

$xLargeFont: 21px;
$largeFont: 18px;
$mediumFont: 14px;
$smallFont: 12px;
