.CustomerInfo {
  .contact-section .read-only-info {
    min-height: 33px;
  }
  .address-section .read-only-info {
    min-height: 86px;
  }
}

@media screen and (max-width: 720px) {
  .contact-section {
    display: flex !important;
    flex-direction: column !important;
  }

  .contact-section * {
    margin-top: 10%;
  }
}
