.bols-table {
  height: 500px;
}

.text-end-2 {
  float: right;
  
}

.bolHead {

  display: flex;
  flex-direction: row;
}

.append {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

.append * {
  margin: 0;
  padding: 0;
}

.left {
  margin-right: 10%
}

.right {
  margin-left: 10%
}


@media screen and (max-width: 720px) {
  .bolHead{
    display: flex;
    flex-direction: column
  }

  .bolHead * {
    padding-top: 10px
  }

  .buttons  {
    transform: rotate(90deg)
  }

  .append {
    align-items: center;
    padding-right: 0
  }

  .left, .right {
    margin: 0
  }
}