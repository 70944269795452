
.buttons-sh {
  display: flex;
  flex-direction: row;
  float: right;
}

.buttons-sh:first-child {
  margin-right: 1%
}

.flex-print-order {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap
}

@media screen and (max-width: 720px) {
  .buttons-sh {
    flex-direction: column
  }
  .buttons-sh * {
    margin-top: 1%
  }
}