.f-right {
    float: right;
    width: 10vw;
}

.ml-5 {
    margin-left: 5%;
}

.mr-5 {
    margin-right: 5%;
}

.invis-bg {
    margin: 1%
}

.bg-dull {
    background: rgba(182, 182, 182, 0);
    color: black;
    border: none;
}

.bg-dull:focus {
    background: rgba(182, 182, 182, 0);
    color: black;
    border: none;
}

.bg-dull:hover {
    background: rgba(117, 158, 245, 0.548);
    color: black;
    border: none;
}

.bg-dull:disabled {
    background: rgba(182, 182, 182, 0);
}

// This is for aligning the line items
.info-header {
    font-weight: bold;
}

.info-credit {
    background: rgba(160, 160, 160, 0.164);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.055);
    border-radius: 5%;
    white-space: nowrap;
}

.credit-item-dropdown {
    margin-top: 1%;

    background-color: rgba(223, 223, 223, 0.288);
    padding: 1%;
}

.credit-comments {
    width: 80%;
}

.pl-5 {
    padding: 1%;
}

.problems {
    width: 100%;
}

.problems-label {
    background: rgba(255, 255, 255, 0);
    color: black;
    font-weight: bold;
}

.autocomplete-input-credit {
    height: 6.25vh;
}

.autocomplete-problems {
    height: 100%;
}

.autocomplete-problems * {
    height: 100%;
}

.hidden {
    display: none;
}

.description-row {
    display: flex;
    flex-direction: row;
}

.description-icon {
    margin-top: 1%;
    margin-right: 2%;
}

.description-field {
    width: 20%;
}

.description-field * {
    width: 100%;
}

.description-delete {
    margin-left: 3%;
}

.error-format {
    width: 98%;
    margin-left: 1%;
}

.ltl-rate-options {
    margin-top: 2%;
    border: rgb(143, 143, 143) 1px solid;
    border-radius: 5%;
    background-color: rgba(184, 219, 255, 0.296);
    padding: 1%;
}

.ltl-rate-options-head {
    border-bottom: black 1px solid;
    padding-bottom: 2%;
    margin-left: 2%;
    margin-right: 2%;
}

.ltl-rate-options-choices {
    margin-left: 2%;
    margin-right: 2%;
}

.tool-drop {
    width: 50px !important;
}

.counter-row {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    gap: 5px;
  }

.disabled {
    color: gray
}

.disabled:hover {
    cursor: default !important;
    background-color: white !important;
}

.disabled:active {
    transform: translateY(0px) !important;
    box-shadow: 0 5px white !important;
}

.counter-btn:hover {
    cursor: pointer;
    background-color: #4a89ff;
    border-radius: 50%;
    height: 3vh
}

.txt-btn {
    color: rgb(18, 186, 241)
}

.txt-btn:hover {
    cursor: pointer;
    color: purple
}

.collapse-item {
    text-align: right;
    margin-right: 5%;
    color: rgb(18, 186, 241)
}

.collapse-item:hover {
    cursor: pointer;
    color: purple;
}

.counter-btn:active {
    background-color: #4a89ff;
    box-shadow: 0 5px #666;
    transform: translateY(2px);
  }

.image-slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.image-parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.arrow {
    padding-top: 0;
    margin-top: 0;
    position: relative;
    margin-top: 6%;
    padding-top: 3%;
    padding-bottom: 7%;
    height: 8%;
    width: 5%;
    text-align: center;
}

.arrow:hover {
    cursor: pointer;
    background-color: rgb(185, 185, 185)
}

.show-image-button {
    margin-top: 2%;
    margin-left: 2%;
    width: fit-content;
    color: rgb(42, 42, 255)
}

.show-image-button:hover {
    cursor: pointer;
    color: lightblue
}

.credit-image-container {
    width: 300px;
    max-height: 80%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.large-image-container {
    z-index: 9999 !important;
    width: 60vw;
    height: 100vh;
    left: 20%;
    top: 10%;
}

.large-image-container img {
    background-position: center
}

.not-large-image-container:hover {
    cursor: pointer;
    transform: scale(1.05)
}

.image-tracker {
    width: 15px;
    height: 15px;
    background: rgb(163, 163, 163);
    border-radius: 50%;
}

.tracker-image-selected {
    background: rgb(88, 88, 88);
}

.image-tracker-container {
    display: flex;
    margin-top: 5;
    justify-content: center;
    gap: 3%;
}

.grow {
    min-width: 25% !important;
}

.footnote {
    font-size: 80%;
    color: gray;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

@media screen and (max-width: 720px) {
    .mobile-vert {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }

    .mobile-vert div div *, .mobile-vert div * {
        width: 100%;
    }

    .bg-dull {
        width: 100%;
    }

    .description-field * {
        width: 70vw;
    }
    .description-delete {
        width: 100%;
        float: right;
    }

    .description-delete * {
        float: right;
    }

    .label-row {
        display: grid;
    }

    .g-row {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    
      .g-line {
        grid-column-start: 1;
        grid-column-end: 4/3;
      }
    


    .credit-item-dropdown * {
        display: grid;
      }

      .credit-comments {
        width: 100%
      }

      .m-100 {
        width: 100%;
      }

      .cust-return {
        display: grid;
      }

      .cust-return-name {
        width: 100%;
      }

      .cust-return-name * {
        width: 100% !important; 
      }

      .cust-return * * {
        width: 100% !important;
      }

      .mobile-btn {
        justify-content: center;
        margin-bottom: 2%;
      }

      .mobile-btn * {
        width: 100%;
      }

      .image-slider {
        display: flex;
        gap: 20px;
      }

      .delete-image {
        display: flex;
        justify-content: center;
        vertical-align: middle;
      }

      .arrow {
        height: 50px;
        width: 30px;
      }

      .image-tracker-container {
        display: flex;
        flex-direction: row;
      }

      .credit-image {
        width: 60vw;
     
    }

    .large-image-container {
        top: 30%;
    }
}